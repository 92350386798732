import { z } from "zod";
import { newUTCDate } from "~/utils/dateHelpers";

// eslint-disable-next-line
declare const serialisedDateSymbol: unique symbol;

export type SerialisedDate = string & {
  __serialised_date__: typeof serialisedDateSymbol;
};
export type DeserialiseDates<T> = T extends SerialisedDate
  ? Date
  : T extends object
    ? { [key in keyof T]: DeserialiseDates<T[key]> }
    : T;

export function serialiseDate(date: Date): SerialisedDate {
  return date.toISOString() as SerialisedDate;
}
export function deserialiseDate(date: SerialisedDate | string): Date {
  return newUTCDate(date as string);
}

export const serialisedDateSchema: z.Schema<SerialisedDate> = z.string() as any;
